.distribution {
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
  font-size: 1em;
}

.distribution-content {
  width: 75%;
  margin-top: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.distribution p {
  margin-bottom: 10px;
}
.distribution-table {
  width: 100%;
  margin-bottom: 20px;
  border: 5px solid #fff;
  border-top: 5px solid #fff;
  border-bottom: 3px solid #fff;
  border-collapse: collapse;
  outline: 3px solid #3695d9;
  font-size: 15px;
  background: #fff !important;
}
.distribution-table th {
  font-weight: bold;
  padding: 7px;
  background: #3695d9c8;
  border: none;
  text-align: center;
  font-size: 15px;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #3695d9;
  color: white;
}
.distribution-table td {
  padding: 7px;
  border: none;
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
  font-size: 15px;
}
.distribution-table tbody tr:nth-child(even) {
  background: #f8f8f8 !important;
}
@media screen and (max-width: 1200px) {
  .distribution {
    margin-right: 0px;
  }
  .distribution-content {
    width: 100%;
    padding: 20px;
    margin-right: 0px;
  }
  .header-h2 {
    margin-left: 20px;
  }
}
@media screen and (max-width: 550px) {
  .distribution-table {
    width: 100%;
    padding: 10px;
  }
  .distribution-table th {
    font-size: 10px;
  }
  .distribution-table td {
    font-size: 10px;
  }
  .distribution-table td p {
    font-size: 10px;
  }
}
@media screen and (max-width: 390px) {
  .distribution-table {
    margin-left: -10px;
  }
}
