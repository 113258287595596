* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
.footer {
  background-color: #222;
  display: flex;
  width: 100%;
  height: max-content;
  position: relative;
}
.footer-wrapper {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px;
  margin-bottom: 70px;
}
.footer-contacts {
  display: flex;
  flex-direction: column;
  width: 20%;
  font-size: 1em;
}
.footer-line {
  border-top: 1px solid grey;
  position: absolute;
  width: 100%;
  text-align: center;
}
.hr {
  height: 1px;
  background-color: grey;
  border: none;
  margin-top: 100px;
}
.footer p {
  font-size: 14px;
  color: #ffffff;
  opacity: 0.6;
  margin-bottom: 4px;
}
.footer-link {
  color: #ffffff;
  opacity: 0.6;
}
.footer-link:hover {
  color: #3695d9;
  opacity: 1;
  transition: all 0.6s ease;
}

.footer-icon {
  margin-left: 15px;
  margin-top: 3px;
  height: 18px;
  width: 18px;
  cursor: pointer;
  transition: color, transform 500ms ease;
}
.footer button {
  width: max-content;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  padding: 10px 21px;
  background-color: rgba(28, 28, 28, 0);
  border-radius: 6px;
  border: 2px solid white;
  margin-top: 30px;
  color: white;
  opacity: 0.6;
  margin-bottom: 50px;
}
.footer button:hover {
  background: #3695d9;
  color: #ffffff;
  border: 2px solid #3695d9;
  transition: all 0.6s ease;
  opacity: 1;
}
.footer-year {
  position: absolute;

  width: 100%;
  bottom: 0;
  text-align: center;
}
.footer-info {
  list-style-type: none;
  width: 20%;
  font-size: 1em;
}

.footer-info ul li {
  list-style-type: none;
  opacity: 0.6;
}

.footer-info .Link {
  display: block;
  padding: 4px;
  opacity: 0.6;
  color: white;
  text-decoration: none;
}

.map {
  transition: 300ms ease;
  width: max-content;
  height: 90%;
  margin-top: -4px;
  border-radius: 4px;
}
.products-button {
  margin-left: -16px;
  margin-top: -15px;
}
.icon-link {
  display: block;
  padding: 4px;
  opacity: 0.6;
  color: #3695d9;
  text-decoration: none;
  transition: color, transform 500ms ease;
  width: 40px;
  height: 40px;
  opacity: 1;
}
.icon-link:hover,
.icon-link:active {
  transform: scale(1.5);
}

.footer-info .Link:hover {
  background-color: none;
  opacity: 1.6;
}
.footer-year-p {
  margin-top: 10px;
}

@media screen and (max-width: 1020px) {
  .footer-contacts p {
    font-size: 0.8em;
  }
  .footer-info {
    font-size: 0.8em;
  }
  .map {
    display: none;
  }
}
@media screen and (max-width: 635px) {
  .footer {
    flex-wrap: wrap;
    align-content: space-between;
  }
  .footer-contacts button {
    display: none;
  }
  .footer-info {
    font-size: 0.8em;
    margin-bottom: 20px;
  }
  .icon-link {
    margin-left: -40px;
  }
}
@media screen and (max-width: 420px) {
  .footer-info {
    width: 40%;
  }
  .footer-info-adapt {
    display: none;
  }
}
