.news {
  display: flex;
  justify-content: space-between;
}
.news-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 70%;
  margin-top: 20px;
  margin-right: 50px;
}
.new-card {
  margin-left: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 45%;
  -webkit-box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.2);
  margin-bottom: 50px;
}
.new-card img {
  width: 300px;
  margin-bottom: 10px;
}
.new-card h6 {
  font-weight: 300;
  opacity: 0.8;
}
.new-card h4 {
  margin-bottom: 20px;
  font-size: 20px;
}
.news-p {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column-reverse;
}
.news-p p {
  margin-top: auto;
}
.new-card p {
  bottom: 0;
  margin-top: 10px;
}
.news-page {
  display: flex;
  justify-content: space-between;
}
.news-page-content {
  display: flex;
  width: 70%;
  flex-direction: column;
  margin-right: 50px;
  align-items: center;
}
.news-page-content h3 {
  margin-top: 10px;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  opacity: 0.8;
}
.news-page-content img {
  max-width: 600px;
}
.news-page-content p {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 400;
}
@media screen and (max-width: 1200px) {
  .news-content {
    width: 100%;
    margin-right: 0;
  }
}
@media screen and (max-width: 780px) {
  .new-card {
    width: 90%;
  }
}
