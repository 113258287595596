.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.login-form {
  border: 1px solid #ccc;
  padding: 20px;
  max-width: 300px;
  width: 100%;
}

.login-form h2 {
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
}

.login-form input {
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
}

.login-form button {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin: 0;
}

.login-form button:hover {
  background-color: #45a049;
}

.login-container p {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  color: red;
}
