.a {
  text-decoration: none;
}

.reporting-links {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 5px;
}
.reporting-link {
  text-decoration: none;
  color: #3695d9;
  margin-bottom: 8px;
}
.reporting-link:hover {
  text-decoration: none;
  color: orange;
  margin-bottom: 8px;
}
.dropdown-reporting-open {
  margin-bottom: 10px;
}
