.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.fullscreen {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  max-width: 50%;
  max-height: 50%;
  margin-bottom: 200px;
}

.modal-content img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.close {
  color: white;
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}
.slider-wrap {
  height: max-content;
  margin-bottom: 50px;
}

.slick-arrow {
  z-index: 1;
  width: 40px;
  height: 40px;
  opacity: 0.8;
}

.slick-arrow:before {
  font-size: 30px;
}
.slick-next {
  right: 0;
}
.slick-prev {
  left: 0;
}

.btn-wrap {
  text-align: center;
  width: 100%;
}
button {
  background-color: #ddd;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 10px;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.5s;
}
button.slick-disabled {
  opacity: 0.6;
}

@media screen and (max-width: 1000px) {
  .modal-content {
    max-width: 80%;
    max-height: 80%;
  }
}
.slider-wrap-cafe {
  margin: 0 10px;
}

@media screen and (max-width: 600px) {
  .modal-content {
    margin-top: 250px;
    max-width: 98%;
    max-height: 100%;
  }
}
