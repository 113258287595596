.contentField {
  display: flex;
  -webkit-box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.2);
  margin-right: 70px;
  height: max-content;
  flex-direction: column;
  margin-bottom: 20px;
}
.content {
  margin-left: 50px;
}
.content-header {
  margin-top: 20px;
  font-size: 1.2em;
  width: 50%;
  color: rgb(9, 84, 197);
}
.content-desc {
  display: flex;
  font-size: 1em;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 20px;
}
.content-salary {
  float: right;
  margin-right: 20px;
}
@media screen and (max-width: 1200px) {
  .contentField {
    width: 100%;
    padding: 20px;
  }
}
@media screen and (max-width: 780px) {
  .content-header {
    font-size: 1.2em;
  }
  .content-desc p {
    font-size: 0.8em;
    margin-bottom: 10px;
  }
  .content-salary {
    font-size: 0.8em;
  }
}
@media screen and (max-width: 650px) {
  .content {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .content-header {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .content-desc {
    margin-top: 10px;
    width: 100%;
    font-size: 20px;
  }
  .content-salary {
    float: none;
    text-align: left;
  }
}
@media screen and (max-width: 650px) {
  .content {
    margin-left: 10px;
  }
}
