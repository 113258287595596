.cafe {
  display: flex;
  justify-content: space-between;
}
.cafe-content {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-right: 50px;
  margin-bottom: 50px;
  margin-top: -60px;
}

.cafe-adress h3 {
  color: #3695d9;
}
.cafe-icon-link {
  display: flex;
  width: max-content;
  align-items: center;
}
.cafe-icon-link img {
  width: 30px;
}

.cafe-work-time p {
  margin-bottom: 5px;
}

.cafe-panel {
  width: 100%;
  height: 70px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid rgb(206, 206, 206);
  border-radius: 4px;
  display: flex;
}
.cafe-panel button {
  color: black;
  background-color: white;
  font-size: 16px;
  opacity: 0.8;
  cursor: pointer;
}

.cafe-panel .active {
  color: rgb(12, 101, 235);
  font-weight: 700;
  opacity: 1;
}
.cafe-adress {
  margin-left: 10px;
  width: 95%;
  display: flex;
  flex-direction: column;
}
.cafe-adress h1 {
  font-size: 20px;
  color: rgb(12, 101, 235);
  margin-bottom: 10px;
}
.cafe-adress p {
  margin: 0;
  margin-bottom: 10px;
}

.cafe-contatcs {
  margin-left: 10px;
  width: 95%;
}

.cafe-contatcs h1 {
  font-size: 20px;
  color: rgb(12, 101, 235);
  margin-bottom: 10px;
}
.cafe-icon-link-p {
  margin: 0;
  margin-left: 5px;
  font-size: 18px;
  font-weight: 400;
}
.cafe-slider {
  margin-right: 20px;
  margin-bottom: 20px;
  margin-left: -15px;
  margin-top: 10px;
}
.cafe-slider-img {
  height: 300px;
  border-radius: 4px;
  width: 100%;
}
.cafe-slider-wrap-img {
  width: 97% !important;
}
@media screen and (max-width: 1400px) {
  .cafe-grid {
    display: grid;
    padding: 50px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 200px 200px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .cafe-content {
    width: 100%;
    padding: 10px;
    margin: 0;
  }
}
@media screen and (max-width: 960px) {
  .cafe-grid {
    display: grid;
    padding: 50px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 300px 300px 300px 300px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    width: 100%;
  }
}
@media screen and (max-width: 786px) {
  .cafe-grid {
    display: flex;
    flex-direction: column;
  }
  .cafe-child-grid {
    height: 230px;
  }
}
@media screen and (max-width: 600px) {
  .cafe-slider {
    margin: 0;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 100% !important;
  }
  .cafe-slider-wrap-img {
    width: 100%;
  }
  /* .cafe-slider-img {
    height: auto;
  } */
}
