.hostel {
  display: flex;
  justify-content: space-between;
}
.hostel-content {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-top: 20px;
  margin-right: 50px;
}

.hostel-content-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hostel-content h3 {
  font-size: 20px;
  opacity: 0.8;
}
.hostel-content h5 {
  font-size: 16px;
  margin-bottom: 20px;
}
.hostel-content p {
  margin-top: 20px;
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 1.38;
}
.hostel-content-adress {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.hostel-map {
  width: 100%;
  margin-right: 16px;
}
.hostel-map iframe {
  width: 100%;
  padding: 10px;
  height: 300px;
  border-radius: 4px;
}
.hostel-slider {
  margin-top: 10px;
  width: 100%;
}
.hostel-content-adress-and-workTime {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 1200px) {
  .hostel-content {
    width: 100%;
    padding: 10px;
    margin-left: 8px;
  }
}
@media screen and (max-width: 900px) {
  .hostel-content {
    width: 100%;
    padding: 10px;
  }
  .hostel-content-desc {
    flex-direction: column;
  }
  .hostel-content-adress {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
  }
}
@media screen and (max-width: 900px) {
  .hostel-content-adress h3 {
    font-size: 18px;
    margin-left: 5px;
  }
  .hostel-content-adress h5 {
    font-size: 10px;
    margin-left: 5px;
  }
}
