.scroll-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background: #3695d9;
  color: white;
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 24px;
  border-radius: 50%;
  line-height: 50px;
  display: none;
  z-index: 1000;
}

.scroll-to-top:hover {
  cursor: pointer;
}

.fa-chevron-up img {
  width: 50px;
  height: 50px;
}
.fade-in {
  animation: fadein 1s forwards;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-out {
  animation: fadeout 1s forwards;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
