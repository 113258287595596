.appeals {
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
}
.appeals-content {
  width: 75%;
  margin-top: 20px;
}
.appeals h3,
h4 {
  text-align: center;
  opacity: 0.8;
}
.appeals h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
.appeals h4 {
  margin-bottom: 50px;
  font-size: larger;
}
.appeals p {
  font-size: 16px;
}
.appeals ul {
  margin-left: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  opacity: 0.8;
  list-style: disc;
}
.appeals li {
  color: #3695d9;
}
.appeals span {
  color: black;
}
@media screen and (max-width: 1200px) {
  .appeals-content {
    width: 100%;
    padding: 20px;
  }

  .header-h2 {
    margin-left: 20px;
  }
}
