.menu {
  position: sticky;
  top: 120px;
  background-color: white;
  border: 1px solid #ccc;
  border-bottom: none;
  border-radius: 4px;
  height: max-content;
  width: 20%;
  margin-right: 50px;
  margin-left: 30px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.sticky {
  margin-bottom: 20px;
}
.menu a {
  border-bottom: 1px solid #ccc;
  display: block;
  padding: 20px;
  color: #333;
  text-decoration: none;
}

.menu a:hover {
  color: rgb(9, 84, 197);
}

.a-active {
  border-bottom: 1px solid #ccc;
  display: block;
  padding: 20px;
  font-weight: bold;
  text-decoration: none;
  background-color: #e5e2e2;
}
@media screen and (max-width: 1200px) {
  .menu {
    display: none;
  }
}
