.price-list {
  display: flex;
  justify-content: space-between;
}
.price-content {
  width: 70%;
  margin-top: 20px;
}
.price-links {
  display: flex;
  flex-direction: column;
  width: 80%;
}
.price-link {
  border: 1px solid rgb(221, 217, 217);
  margin-bottom: 20px;
  padding: 30px;
  width: 100%;
  -webkit-box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.2);
}
.price-content h3 {
  opacity: 0.7;
  font-weight: 500;
  margin-bottom: 20px;
}
.price-link img {
  width: 70px;
  height: 100%;
}
.link-img-text {
  display: flex;
  justify-content: center;
}
.link-img-text p {
  margin-top: 20px;
  margin-left: 20px;
  font-size: 16px;
}
@media screen and (max-width: 1200px) {
  .price-content {
    width: 100%;
    padding: 20px;
  }
  .price-links {
    width: 100%;
  }
  .header-h2 {
    margin-left: 20px;
  }
}
