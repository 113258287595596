.MainReturnButton {
  margin-left: 50px;
  opacity: 0.7;
  color: #222;
  text-decoration: none;
}
.MainReturnButton:hover {
  background-color: none;
  color: rgb(9, 84, 197);
}
@media screen and (max-width: 1200px) {
  .MainReturnButton {
    margin-left: 20px;
  }
}
