.delivery {
  display: flex;
  justify-content: space-between;
}
.delivery-content {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-top: 20px;
  margin-right: 50px;
}
.delivery img {
  margin-bottom: 20px;
}
.delivery ul {
  margin-left: 20px;
  margin-bottom: 10px;
  opacity: 0.8;
  list-style: disc;
}
.delivery li {
  color: #3695d9;
}
.delivery span {
  color: black;
}
@media screen and (max-width: 1200px) {
  .delivery-content {
    width: 100%;
    padding: 20px;
  }
  .header-h2 {
    margin-left: 20px;
  }
}
