* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app {
  background-image: url("/public/images/background-main.jpg");
  background-size: cover;
  background-size: no-repeat;
  background-position: center center;
  display: flex;
  height: max-content;
}
/* header  header  header  header  header  header  header  header  header  header  header  header  header  header  header  header  header */
.bm-burger-button {
  position: relative;
  width: 30px;
  margin-left: 10px;
  margin-top: -40px;
  height: 30px;
  left: 36px;
  top: 25px;
}
.myMenu {
  background: rgb(9, 84, 197);
  top: 0;
  padding: 2.5em 1.5em 0;
}

.myMenu a {
  color: white;
  font-size: 1.1em;
  padding: 0.8em;
  width: 50% !important;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0;
}

/* react-burger-menu */
.header {
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 100;
  background-color: rgba(225, 230, 238, 0.9);
  /* background-color: rgba(231, 229, 229, 0.9); */
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 80px;
  z-index: 1;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
}
.header_drop {
  position: fixed;
  top: -100px;
  left: 0;
  width: 100%;
  transition: top 0.3s ease-in-out;
  z-index: 1000;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
}
.header-menu-btn {
  margin-left: 30px;
}
.header h3 {
  width: 20%;
}
.header-logo {
  width: 50%;
  margin-left: 50px;
}
.main-navbar {
  display: flex;
  width: 60%;
  justify-content: center;
  align-items: stretch;
  font-size: 16px;
  font-weight: 600;
}
@media screen and (max-width: 960px) {
  .logo {
    margin-right: 30px;
  }
  .main-navbar {
    display: none;
  }
  .header-menu-btn {
    margin-left: 30px;
  }
}
@media screen and (max-width: 600px) {
  .header a {
    width: 20%;
    margin-right: 20px;
  }
  .bm-burger-button {
    margin-left: -25px;
  }
  .header-logo {
    margin-right: 10px;
    width: 50%;
    margin-top: 5px;
  }
  .header {
    height: 50px;
  }
}
.main-navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.main-navbar li {
  margin: 0 10px;
}
.dropdown-burger-menu {
  margin-top: -10px;
}
.MenuItem {
  margin-top: 20px;
  margin-bottom: 0;
}

.main-navbar .Link {
  display: block;
  padding: 10px;
  color: #222;
  text-decoration: none;
}

.Link:hover {
  background-color: none;
  color: rgb(9, 84, 197);
}

.dropdown {
  position: absolute;
}

.dropdown ul {
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 200px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
}
/* FIRSTPAGE FIRSTPAGE FIRSTPAGE FIRSTPAGE FIRSTPAGE FIRSTPAGE FIRSTPAGE FIRSTPAGE FIRSTPAGE FIRSTPAGE FIRSTPAGE FIRSTPAGE FIRSTPAGE FIRSTPAGE FIRSTPAGE FIRSTPAGE FIRSTPAGE */
.first-page {
  margin-top: -80px;
  margin-left: 10%;
  color: white;
  text-shadow: rgb(8, 8, 8) 1px 0 1px;
}
.first-page-wrapper {
  display: flex;
  text-align: center;
  margin-left: -40px;
  margin-top: 30px;
}
.first-page h1 {
  padding-top: 200px;
  font-size: 3em;
}
.first-page h4 {
  font-size: 32px;
  opacity: 1;
}
.first-page p {
  width: 200px;
  opacity: 1;
}
.first-page button {
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  padding: 10px 21px;
  background-color: rgba(28, 28, 28, 0);
  border-radius: 6px;
  border: 2px solid white;
  margin-right: 30px;
  margin-top: 30px;
  color: white;
  margin-bottom: 200px;
}
.first-page button:hover {
  background: #3695d9;
  color: #ffffff;
  border: 2px solid #3695d9;
  transition: all 0.6s ease;
}
@media screen and (max-width: 635px) {
  .first-page h1 {
    font-size: 2em;
  }
  .first-page-wrapper {
    display: none;
  }
  .first-page button {
    font-size: 1em;
    padding: 0.5em;
  }
}
/* ProductProductProductProductProductProductProductProductProductProductProductProductProductProductProductProductProductProductProduct */
.product-main-list {
  display: flex;
  width: 90%;
  margin-left: 5%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 50px;
  margin-top: 130px;
}
.product {
  width: 30%;
  box-sizing: border-box;
  height: 230px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border: 1px solid rgb(206, 199, 199, 0.4);
  border-radius: 4px;
  padding: 10px;
}

.product:hover {
  width: 30%;
  height: 230px;
  padding-bottom: 20px;
  border: 1px solid rgb(206, 199, 199);
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 29px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 29px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 29px 0px rgba(34, 60, 80, 0.2);
  transition: all 0.6s ease;
  color: rgb(9, 84, 197);
  cursor: pointer;
}
.products h1 {
  margin-top: 20px;
  font-size: 44px;
  opacity: 0.9;
}
.products p {
  width: 70%;
  margin-top: 20px;
  color: grey;
  float: left;
}
.product h3 {
  margin-top: 20px;
  margin-left: 20px;
  width: 50%;
  margin-bottom: 20px;
  opacity: 0.8;
}
.product h5 {
  opacity: 0.6;
  font-size: 12px;
  margin-top: -15px;
  margin-left: 20px;
  width: 50%;
}
.product img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  float: right;
  margin-right: 30px;
  margin-top: -40px;
}
.product-desc {
  width: 65%;
  margin-left: 20px;
  margin-top: 20px;
  opacity: 0.6;
  font-size: 1em;
  margin-bottom: 10px;
}
.description {
  margin: 60px;
}
.products button {
  float: right;
  border: none;
  background-color: rgba(9, 84, 197, 0);
  font-size: 16px;
  transition: color, transform 500ms ease;
}
.products button:hover {
  float: right;
  border: none;
  color: #3695d9;
  background-color: rgba(9, 84, 197, 0);
  font-size: 16px;
  cursor: pointer;
  transition: transform;
}
.description span {
  display: none;
}
@media screen and (max-width: 960px) {
  .description button {
    display: none;
  }
  .description h1 {
    font-size: 2em;
  }
  .description span {
    display: inline;
    font-size: 40px;
    float: right;
  }
}
@media screen and (max-width: 1240px) {
  .product {
    width: 48%;
  }
  .product:hover {
    width: 48%;
  }
}
@media screen and (max-width: 835px) {
  .product {
    width: 100%;
  }
  .product:hover {
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .product {
    height: max-content;
  }
  .product:hover {
    height: max-content;
  }
}
/* icon-links */

/* .icons-links {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: max-content;
  background-color: whitesmoke;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
} */
/* .icons-link {
  width: 25%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 50px;
} */
.icons-links {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: max-content;
  background-color: whitesmoke;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
}
.icons-link {
  width: 20%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 50px;
}
.icons-link a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.icons-links-hr {
  opacity: 0;
  color: #888;
}
@media screen and (max-width: 1300px) {
  .icons-links {
    padding: 0;
  }
  .icons-link {
    margin-left: 0;
    margin-right: 0;
  }
  .icons-link h3 {
    font-size: 18px;
  }
  .icons-link p {
    font-size: 14px;
    max-width: 70%;
  }
}
@media screen and (max-width: 1100px) {
  .icons-links {
    justify-content: row;
    padding: 20px;
  }
  .icons-link {
    width: 15%;
  }
}
@media screen and (max-width: 660px) {
  .icons-link {
    width: 100%;
  }
  .icons-link h3 {
    font-size: 24px;
  }
  .icons-link p {
    font-size: 16px;
  }
}
.product-main-list-link {
  width: 30%;
}
/* burger-menu */
.MenuItem {
  display: flex;
  flex-direction: column;
  height: max-content;
}
.bm-menu {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: rgb(9, 84, 197) rgb(9, 84, 197);
}

.bm-menu::-webkit-scrollbar {
  width: 4px;
}

.bm-menu::-webkit-scrollbar-thumb {
  background-color: rgb(9, 84, 197);
  border-radius: 2px;
}

.bm-menu::-webkit-scrollbar-track {
  background-color: rgb(9, 84, 197);
  border-radius: 2px;
}
.burger-menu-span {
  color: antiquewhite;
  font-size: 8px;
}
.burger-link {
  font-size: 0.9em;
  margin-left: 30px;
}
/* video */
.video {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: whitesmoke;
  padding: 20px;
}
.video h1 {
  opacity: 0.8;
  margin-left: 50px;
  font-size: 40px;
}
.video-description {
  width: 55%;
}
.video-description-second-h1 {
  margin: 0;
  margin-top: 50px;
}
.video h5 {
  margin: 0;
  margin-top: 10px;
  margin-left: 50px;
  font-size: 20px;
  margin-bottom: 20px;
  font-style: italic;
}
.videos {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.video-wrapper {
  margin-top: 20px;
  display: flex;
  text-align: center;
  margin-right: 30px;
  width: 50%;
}
.video-wrapper video {
  width: 100%;
  border-radius: 8px;
  border-radius: 1px solid grey;
}

@media screen and (max-width: 1200px) {
  .video {
    flex-direction: column;
  }
  .video-description {
    padding: 20px;
    width: 100%;
  }
  .video-description h1,
  h2,
  h5 {
    margin: 0;
  }
  .video h5 {
    margin-left: 0;
  }
  .videos {
    width: 100%;
    align-items: center;
  }

  .video-wrapper {
    margin: 0;
    margin-bottom: 20px;
    width: 70%;
  }
}
@media screen and (max-width: 635px) {
  .video-description {
    padding: 10px;
  }
  .video-description h1,
  h2,
  h5 {
    margin: 0;
  }
  .video-p {
    margin-left: 0;
  }
  .video-wrapper {
    width: 100%;
  }
  .video iframe {
    margin: 0;
    width: 95%;
    height: 250px;
    margin-top: -10px;
    margin-bottom: 10px;
  }
}
/* news-slider1111111111111111111111111111111111111111 */
.news-slider {
  margin-bottom: 20px;
  padding: 10px;
}
.slider-news-wrap {
  padding: 30px;
  width: 93%;
  margin-left: 50px;
}
.news-slider-date {
  font-size: 10px;
  font-weight: 200;
  margin-top: -5px;
}
@media screen and (max-width: 1073px) {
  .news-slider {
    display: none;
  }
}

/* /////////////////////////////////////////////////////////////////// */
@media screen and (min-width: 2000px) {
  .wrapper {
    width: 1920px;
    margin: 0 auto;
  }
}
.error-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 80vh;
}
.error {
  font-size: 30px;
  height: max-content;
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
  width: 70%;
  margin-top: 100px;
}

.loader-wrapper-app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100%;
}
.loader {
  border: 6px solid #3498db; /* Light grey */
  border-top: 6px solid #f3f3f3; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
