* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.about-container {
  display: flex;
}
.about-content {
  float: right;
  width: 70%;
}
.about-content h2 {
  margin-bottom: 20px;
  margin-top: 15px;
}
h2 {
  opacity: 0.8;
  margin-left: 50px;
  margin-top: 50px;
  font-size: 36px;
}
ul {
  list-style: none;
}
p {
  opacity: 0.8;
  width: 100%;
  margin-top: 20px;
  float: right;
  font-size: medium;
  margin-right: 20px;
  margin-bottom: 20px;
  font-weight: 300;
}

.about-content-card {
  display: flex;
  box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.2);
  height: 250px;
  flex-direction: row;
  margin-bottom: 20px;
  padding: 20px;
}

.about-content-card h3 {
  font-family: "Roboto", Arial, sans-serif;
  opacity: 0.8;
  font-size: 2em;
}

.about-content-card p {
  margin-top: 0;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 1em;
  width: 70%;
}
.about-content-card img {
  border-radius: 5px;
  width: 25%;
}
.about-today {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  margin-left: 20px;
}
.about-today li {
  list-style: none;
  margin-bottom: 5px;
  opacity: 0.8;
  font-size: 16px;
  font-weight: 400;
}
.about-today p {
  opacity: 0.9;
  font-size: 16px;
  font-weight: 400;
}
.about-today h2 {
  text-align: center;
  font-size: 30px;
}
@media screen and (max-width: 1200px) {
  .about-content {
    width: 100%;
    padding: 20px;
  }
  .header-h2 {
    margin-left: 20px;
  }
  .about-today {
    margin-left: 0;
  }
  .about-today h2 {
    font-size: 30px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 757px) {
  .about-content-card {
    flex-direction: column;
    height: max-content;
    align-items: center;
  }
  .about-content-card p {
    width: 100%;
    font-size: 0.8;
  }
  .about-content-card img {
    width: 50%;
    height: 50%;
  }
}
@media screen and (max-width: 600px) {
  .about-content-card img {
    width: 100%;
  }
}
