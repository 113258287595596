.product-list {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin-right: 20px;
  width: 100%;
}

.product-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 70%;
  margin-right: 30px;
  margin-top: 28px;
}

.product-card {
  color: black;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  padding: 30px;
  width: 48%;
}
.product-card:hover {
  transition: box-shadow ease 0.3s, border ease 0.3s;
  -webkit-box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.2);
}
.product-card-wrapper {
  display: flex;
}
.icon-image {
  width: 55px;
  height: 50px;
  margin-right: 30px;
}
.product-card-desc {
  width: 80%;
  float: right;
}
.product-card-desc h3 {
  font-family: "Roboto", Arial, sans-serif;
  opacity: 0.8;
}
.product-card-desc p {
  margin-top: 20px;
  font-size: 1em;
  opacity: 0.6;
}

@media screen and (max-width: 1200px) {
  .product-content {
    width: 100%;
    margin-right: 0;
    padding: 20px;
  }
}
@media screen and (max-width: 760px) {
  .product-content {
    flex-wrap: wrap;
  }
  .product-card {
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  .product-card {
    padding: 20px;
  }
}
