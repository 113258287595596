.admin-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px;
}
.admin-content h2 {
  margin-bottom: 20px;
  margin-left: 0;
}
/* Стилизация самой формы */
form {
  width: 100%;
  max-width: 500px;

  padding: 20px;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Стилизация label */
form label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

/* Стилизация полей для ввода текста (input, file) */
form input[type="text"],
form input[type="file"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 20px;
}

/* Стилизация кнопки для отправки */
form input[type="submit"] {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

form input[type="submit"]:hover {
  background-color: #0056b3;
}

form textarea {
  width: 100%;
  height: 150px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  resize: vertical; /* Разрешить изменение размеров только по вертикали */
  margin-bottom: 20px;
  resize: none;
}

label {
  font-size: 18px;
}

.admin-content {
  margin-top: 20px;
}
.admin-content .red-btn {
  background-color: rgb(197, 44, 44);
  color: #f8f8f8;
  width: 120px;
  height: 40px;
  font-size: 12px;
  padding: 5px;
}
.admin-content button {
  cursor: pointer;
  background-color: #3695d9;
  color: #f8f8f8;
}
.admin-data {
  display: flex;
  flex-direction: column;
}
.blue-text {
  color: #3695d9;
}
.admin-elem {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  margin-bottom: 20px;
  border: 2px solid #3695d9;
  border-radius: 4px;
  padding: 10px;
}
.admin-content img {
  width: 400px;
}
.admin-elem button {
  width: 50%;
}

.admin-link {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  border: 2px solid gray;
  margin-bottom: 20px;
}

.category-table {
  width: 100%;
  border: none;
  margin-bottom: 20px;
}
.category-table thead th {
  font-weight: bold;
  text-align: left;
  border: none;
  padding: 10px 15px;
  background: #d8d8d8;
  font-size: 14px;
}
.category-table thead tr th:first-child {
  border-radius: 8px 0 0 8px;
}
.category-table thead tr th:last-child {
  border-radius: 0 8px 8px 0;
}
.category-table tbody td {
  text-align: left;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  vertical-align: top;
}
.category-table tbody tr:nth-child(even) {
  background: #f3f3f3;
}
.category-table tbody tr td:first-child {
  border-radius: 8px 0 0 8px;
}
.category-table tbody tr td:last-child {
  border-radius: 0 8px 8px 0;
}
.table-characteristics {
  padding: 20px;
}
.item-admin {
  border: 5px solid #3695d9;
  padding: 15px;
  margin-bottom: 50px;
}
.category-admin {
  border: 2px solid rgb(206, 193, 21);
  padding: 15px;
  margin-bottom: 20px;
  background-color: whitesmoke;
}
.characteristics-btn {
  width: 150px;
  font-size: 14px;
  font-weight: 300;
}

.admin-content .characteristics-btn {
  background-color: rgb(24, 148, 80);
}
.category-img {
  width: 400px;
  height: 300px;
  margin-left: 20px;
  margin-bottom: 20px;
}
