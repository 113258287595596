.management {
  display: flex;
}
.management-content {
  width: 73%;
  margin-bottom: 50px;
  margin-top: 32px;
}
@media screen and (max-width: 1200px) {
  .management-content {
    width: 100%;
    padding: 30px;
    font-size: 10px;
  }
  .header-h2 {
    margin-left: 20px;
  }
}
