* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.directorPhotoContacts {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}
.director-photo {
  width: 30%;

  position: relative;
  text-align: center;
  margin-bottom: 20px;
}
.directorPhotoContacts p {
  margin: auto;
}
.directorPhotoContacts img {
  margin: auto;
  height: 400px;
  max-width: 320px;
}

@media screen and (max-width: 970px) {
  .director-photo {
    width: 50%;
  }
}
@media screen and (max-width: 650px) {
  .director-photo {
    width: 100%;
  }
}
