.galery {
  display: flex;
  justify-content: space-between;
}
.galery-content {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-right: 50px;
  margin-bottom: 50px;
}
.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 2fr));
  gap: 10px;
}
.galery-description p {
  font-weight: 400;
  font-size: 16px;
  opacity: 0.8;
  margin-top: 20px;
}
.galery-description h1 {
  font-family: "Montserrat", Arial, sans-serif;
  opacity: 0.8;
}
.image-gallery img {
  width: 100%;
  height: 270px;
  cursor: pointer;
}

.galery-panel {
  width: 100%;
  height: 70px;
  margin-top: 80px;
  margin-bottom: 30px;
  border: 1px solid rgb(206, 206, 206);
  border-radius: 4px;
  display: flex;
}
.galery-panel button {
  color: black;
  background-color: white;
  font-size: 16px;
  opacity: 0.8;
  cursor: pointer;
}
.galery-panel .active {
  color: rgb(12, 101, 235);
  font-weight: 700;
  opacity: 1;
}
@media screen and (max-width: 1200px) {
  .galery-content {
    width: 100%;
    padding: 10px;
  }
  .galery {
    padding: 20px;
    margin-left: 20px;
  }
}
@media screen and (max-width: 600px) {
  .galery {
    width: 100%;
    margin: 0;
    padding: 5px;
  }
  .galery-content {
    width: 100%;
    padding: 5px;
    margin: 0;
  }
  .galery-description h1 {
    display: none;
  }
  .galery-panel {
    justify-content: space-evenly;
    padding: 2px;
  }
  .galery-panel button {
    color: black;
    background-color: white;
    font-size: 11px;
    opacity: 0.8;
    width: 25%;
    margin-right: 25px;
    width: 20%;
    text-align: center;
    padding: 0 !important;
  }
  .galery-panel .active {
    color: rgb(12, 101, 235);
    font-weight: 700;
    opacity: 1;
  }
  .image-gallery img {
    height: auto;
  }
}
.slider-img {
  height: 100% !important;
}
