p {
  margin: 0;
  margin-bottom: 20px;
}
a {
  text-decoration: none;
  color: black;
  transition: all 0.5 ease;
}
h5 {
  opacity: 0.6;
  font-weight: 400;
  font-size: 14px;
}
a:hover {
  color: #3695d9;
  transition: color, transform 500ms ease;
}
.jobs {
  display: flex;
  justify-content: space-between;
}
.jobs-content {
  width: 80%;
  margin-top: -50px;
}

.jobs-contacts {
  display: flex;
  margin-left: 50px;
  margin-bottom: 30px;
}
.jobs-contact {
  width: 30%;
  margin-top: 20px;
}
.jobs-greetins {
  margin-bottom: 120px;
  width: 80%;
  margin-left: 50px;
}
.jobs-list {
  margin-left: 20px;
  width: 90%;
  margin-bottom: 20px;
}
.jobs-list h3 {
  margin-bottom: 10px;
  margin-top: 10px;
  opacity: 0.8;
}
.jobs-list ol li {
  margin-bottom: 5px;
  opacity: 0.8;
}
@media screen and (max-width: 1200px) {
  .jobs-content {
    width: 100%;
    padding: 20px;
  }
  .jobs-contacts {
    margin-left: 0px;
    margin-top: 30px;
  }
  .jobs-greetins {
    width: 100%;
    margin-left: 0px;
  }
  .header-h2 {
    margin-left: 20px;
  }
}
@media screen and (max-width: 900px) {
  .jobs-contact {
    width: 100%;

    margin-left: 10px;
  }
}
@media screen and (max-width: 650px) {
}
