.contacts-table {
  width: 100%;
  margin-bottom: 20px;
  border: 5px solid #fff;
  border-top: 5px solid #fff;
  border-bottom: 3px solid #fff;
  border-collapse: collapse;
  outline: 3px solid #3695d9;
  font-size: 15px;
  background: #fff !important;
  text-align: center;
}
.contacts-table th {
  font-weight: bold;
  padding: 7px;
  background: #3695d9c8;
  border: none;
  text-align: center;
  font-size: 15px;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #3695d9;
  color: white;
}
.contacts-table td {
  padding: 7px;
  border: none;
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
  font-size: 15px;
}
.contacts-table tbody tr:nth-child(even) {
  background: #f8f8f8 !important;
}
@media screen and (max-width: 485px) {
  .contacts-table th {
    font-size: 12px;
  }
}
@media screen and (max-width: 485px) {
  .contacts-table {
    margin-left: -5px;
  }
  .contacts-table td {
    font-size: 12px;
  }
}
@media screen and (max-width: 390px) {
  .contacts-table {
    margin-left: -10px;
  }
}
