.contacts-container {
  display: flex;
}
.contacts-content {
  display: flex;
  width: 70%;
  flex-direction: row;
  justify-content: space-around;
}
.contacts-adress {
  width: 50%;
  opacity: 0.8;
}
.contacts-adress h3 {
  color: rgb(9, 84, 197);
}
.contacts-links {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 20px;
}
.contacts-map {
  width: 50%;
}
.contacts-adress h3 {
  margin-bottom: 10px;
}
.contacts-name {
  font-size: 22px;
}
.contacts-icon-link {
  display: block;
  padding: 4px;
  opacity: 0.6;
  color: #3695d9;
  text-decoration: none;
  transition: color, transform 500ms ease;
  width: 40px;
  height: 40px;
  opacity: 1;
}
.contacts-icon-link:hover,
.contacts-icon-link:active {
  transform: scale(1.5);
}
.shop-adress h3 {
  margin-bottom: 5px;
}
@media screen and (max-width: 1200px) {
  .contacts-content {
    width: 100%;
    padding: 20px;
    justify-content: space-around;
  }
}
@media screen and (max-width: 830px) {
  .contacts-content {
    flex-direction: column;
    padding: 20px;
  }
  .contacts-adress {
    width: 100%;
  }
  .contacts-map {
    width: 100%;
  }
}
