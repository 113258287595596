.documents {
  display: flex;
  justify-content: space-between;
}
.documents-content {
  width: 70%;
}
.big-img {
  width: 90%;
}

.documents-a {
  margin-bottom: 20px;
  margin-left: 40px;
  margin-top: 20px;
  display: flex;
}

.documents-link {
  margin-top: 15px;
  text-decoration: none;
  color: rgb(9, 84, 197);
}
.documents-link:hover {
  text-decoration: none;
  color: orange;
  margin-bottom: 3px;
}
.pdf-img {
  width: 50px;
  margin-right: 10px;
}
.documents-h3 {
  opacity: 0.8;
  font-size: 1.5em;
  margin-left: 40px;
  margin-top: 30px;
}
@media screen and (max-width: 1200px) {
  .documents-content {
    width: 100%;
    padding: 20px;
  }
  .header-h2 {
    margin-left: 20px;
  }
  .documents-h3 {
    font-size: 1em;
  }
  .documents-a {
    font-size: 0.8em;
  }
}
@media screen and (max-width: 635px) {
  .documents-a {
    margin-left: 20px;
  }
}
