* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.info-container {
  display: flex;
}
.info-content {
  float: right;
  width: 70%;
}
.info-img {
  width: 100%;
  margin-right: 20px;
  margin-top: 30px;
}
h2 {
  opacity: 0.8;
  margin-left: 50px;
  margin-top: 50px;
  font-size: 36px;
}
.info-p {
  margin-top: 20px;
}
p {
  opacity: 0.8;
  width: 100%;
  margin-top: 20px;
  float: right;
  font-size: 16px;
  font-weight: 400;
  margin-right: 20px;
  margin-bottom: 20px;
}
@media screen and (max-width: 1200px) {
  .info-content {
    width: 100%;
    padding: 10px;
  }
  .header-h2 {
    margin-left: 20px;
  }
}
